<template>
	<div id="Recharge">
		<div class="recharge-title">
			<div class="icon-return" @click="$router.back()"></div>
			升级记录
		</div>
		<div class="replace-record-list">
			<div class="item" v-for="(item,index) in list" :key="index">
				<div class="item-row1">
					<span :class="{'green': item.status==1}">{{ item.status==1?'成功':'失败' }}</span>
					<div class="time">{{ item.created_at }}</div>
				</div>
				<div class="item-row2">
					选择升级概率：{{ item.rate }}%
					<span>升级总投入：{{ item.use_bean }}</span>
				</div>
				<div class="item-row3">
					祭品道具
					<span>使用余额：{{ item.user_bean }}</span>
				</div>
				<div class="gun-list">
					<div class="gun-item" v-for="(item1,index1) in item.box_records" :key="index1">
						<div class="alias" v-if="item1.dura_alias&&item1.dura_alias!='无'">{{ item1.dura_alias }}</div>
						<div class="img-con">
							<img :src="item1.cover" alt="">
						</div>
						<div class="name">{{ item1.name }}</div>
						<div class="price">
							<div class="coins"></div>
							{{ item1.bean }}
						</div>
					</div>
				</div>
				<div class="item-row3">目标道具</div>
				<div class="gun-item">
					<div class="alias" v-if="item.dura_alias&&item.dura_alias!='无'">{{ item.dura_alias }}</div>
					<div class="img-con">
						<img :src="item.cover" alt="">
					</div>
					<div class="name">{{ item.name }}</div>
					<div class="price">
						<div class="coins"></div>
						{{ item.bean }}
					</div>
				</div>
				<div class="hash-open" v-if="item.hash_info" :class="{'opened': item.opened}" @click="item.opened=!item.opened"></div>
				<div class="hash-con" v-show="item.opened">
					<div class="hash-main" v-if="item.hash_info">
						服务器种子HASH：<br>
						{{ item.hash_info.server_seed_hash }}<br>
						服务器种子: {{ item.hash_info.server_seed }}<br>
						用户种子: {{ item.hash_info.user_seed }}<br>
						结果种子Hash:<br>
						{{ item.hash_info.hash }}<br>
						Number= {{ item.hash_info.divisor_hex }}(16进制) / ffffff(16进制) = <br>
						{{ item.hash_info.divisor }}/16777215 = <br>
						{{ item.hash_info.result }}
						<div class="btn-con">
							<div class="btn" @click="openHashUrl(1)">验证</div>
							<div class="btn" @click="openHashUrl(2)">16进制转换</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-con" style="margin-top: 12px;">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		syntheRecords
	} from '@/network/api.js'
	export default {
		name: 'ReplacementRecord',
		data() {
			return {
				active: 0, //tab分栏
				list: [], 

				total:0,//总数据数量
				per_page:10,//一页数据
				page_size:1,//页码
			}
		},
		created() {
			this.syntheRecords()
		},
		methods: {
			openHashUrl(type){
				if(type==1){
					window.open('https://emn178.github.io/online-tools/sha256.html')
				}else{
					window.open('https://tool.oschina.net/hexconvert/')
				}
			},
			syntheRecords() {
				this.list = []
				syntheRecords(this.page_size).then((res) => {
					res.data.data.records.map(item=>{
						item.opened = false
					})
					this.total = res.data.data.total
					// this.per_page = res.data.data.per_page
					this.list = res.data.data.records
				})
			},
			handleCurrentChange(val) {
				this.page_size = val
				this.syntheRecords()
			}
		},

		watch: {
			active(v) {
				// console.log(v)
				this.pay_way = v + 1
				// console.log(this.pay_way)
			}
		}

	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
	.replace-record-list{
		padding-top: 12px;
		.item{
			width: 343px;
			height: 534px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/replace-record-bg.png);
			margin: 0 auto 12px;
			padding: 0 12px;
			position: relative;
			.item-row1{
				@include Scenter;
				justify-content: space-between;
				height: 46px;
				span{
					font-size: 18px;
					color: #FF1A51;
					&.green{
						color: #26D54D;
					}
				}
				.time{
					color: #999;	
				}
			}
			.item-row2{
				@include Scenter;
				justify-content: space-between;
				height: 40px;
				border-top: 1px solid #3C3C4B;
				border-bottom: 1px solid #3C3C4B;
			}
			.item-row3{
				@include Scenter;
				justify-content: space-between;
				height: 40px;
				color: #FFB800;
			}
			.gun-list{
				display: flex;
				overflow-x: auto;
				overflow-y: hidden;
				gap: 6px;
				height: 148px;
				//滚动条宽度
				&::-webkit-scrollbar {
					width: 2px;
					height: 2px;
				}
				/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
				&::-webkit-scrollbar-track {
					background: #343449;
					
				}
				//滚动条设置
				&::-webkit-scrollbar-thumb {
					background: #838399;
				}
				.gun-item{
					flex-shrink: 0;
					width: 156px;
				}
			}
			.gun-item{
				position: relative;
				@include Scenter;
				flex-direction: column;
				border: 1px solid #3C3C4B;
				background: #1D1D28;
				padding: 10px 0 0;
				height: 148px;
				.alias{
					position: absolute;
					right: 4px;
					top: 4px;
					height: 20px;
					line-height: 20px;
					padding-right: 4px;
					background: #FFB800;
					color: #000;
					font-size: 12px;
					&::before{
						content: '';
						position: absolute;
						left: -19px;
						top: 0;
						border-style: solid;
						border-width: 10px;
						border-color: #FFB800 #FFB800 transparent transparent;
					}
				}
				.img-con{
					width: 112px;
					height: 64px;
				}
				.name{
					max-width: 106px;
					@include wordhide;
					margin: 12px 0;
				}
				.price{
					@include Scenter;
					font-size: 15px;
					font-weight: bold;
					.coins{
						width: 20px;
						height: 20px;
						margin-right: 8px;
					}
				}
			}
			.hash-open{
				width: 318px;
				height: 32px;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/hash-replace-btn-bg.png);
				position: absolute;
				bottom: 18px;
				left: 50%;
				transform: translateX(-50%);
				cursor: pointer;
				z-index: 5;
				&::before{
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					width: 74px;
					height: 17px;
					background-size: 100% 100%;
					background-image: url(~@/assets/img/hash-replace-btn-word.png);
					transform: translate(-50%, -50%);
				}
				&.opened{
					background-image: url(~@/assets/img/hash-replace-btn-bg-active.png);
					&::before{
						background-image: url(~@/assets/img/hash-replace-btn-word-active.png);
					}
				}
			}
			.hash-con{
				position: absolute;
				z-index: 3;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				padding-top: 50px;
				background: rgba(0, 0, 0, 0.60);
				.hash-main{
					padding: 12px 18px;
					word-break: break-all;
					width: 320px;
					height: 417px;
					margin: 0 auto;
					background: #1A1A29;
					position: relative;
					font-size: 12px;
					line-height: 20px;
					.btn-con{
						gap: 38px;
						@include Hcenter;
						position: absolute;
						bottom: 24px;
						left: 0;
						width: 100%;
						.btn{
							width: 88px;
							height: 30px;
							@include HScenter;
							color: #15161B;
							background: #FFB800;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>
